input[name='day'] {
    pointer-events: none !important;
}
input[name='month'] {
    pointer-events: none !important;
}
input[name='year'] {
    pointer-events: none !important;
}

.react-daterange-picker__calendar-button__icon {
    stroke: #828282 !important;
}
.react-daterange-picker {
    width: 100%;
    display: inline-flex;
    position: relative;
}
.react-daterange-picker,
.react-daterange-picker *,
.react-daterange-picker *:before,
.react-daterange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-daterange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
}
.react-daterange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border-radius: 4px !important;
    border: 1px solid rgba(17, 17, 19, 0.2) !important;
    padding: 8px 13px !important;
    color: rgba(17, 17, 19, 0.6) !important;
}

.react-daterange-picker__wrapper:focus-within {
    border: 1px solid #0077c0 !important;
    outline: none;
}

.react-daterange-picker__input:focus {
    outline: none;
    border: 1px solid #0077c0;
}
.react-daterange-picker__calendar-button {
    flex-grow: 0 !important;
    align-self: flex-end !important;
    margin-left: auto !important;
}

.react-calendar__tile--active {
    color: white !important;
    background: #006edc !important;
}
.react-calendar__month-view__weekdays__weekday {
    text-transform: capitalize !important;
    text-decoration: none !important;
}

.react-daterange-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
    height: 100%;
    flex-grow: 0 !important;
    padding: 0 2px;
    box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
}
.react-daterange-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
}
.react-daterange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
}
.react-daterange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
}
.react-daterange-picker__button:enabled {
    cursor: pointer;
}
.react-daterange-picker__button:enabled:hover
    .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
    .react-daterange-picker__button__icon {
    stroke: #0078d7;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
    stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
    display: inherit;
}
.react-daterange-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
}
.react-daterange-picker__calendar--closed {
    display: none;
}
.react-daterange-picker__calendar .react-calendar {
    border-width: thin;
}

.react-daterange-picker__calendar-button svg {
    display: none !important;
}

.react-daterange-picker__button {
    background-image: url('../../assets/icons/calender2.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    border: none;
}

.holiday {
    color: #d9534f !important;
    cursor: not-allowed;
    position: relative;
}

.holiday-tooltip {
    position: fixed;
    background-color: #fff;
    color: #4d5461;
    padding: 12px;
    border-radius: 8px;
    font-size: 11px;
    white-space: nowrap;
    z-index: 9999;
    pointer-events: none;
    transform: translateX(-50%);
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 24px 0px rgba(8, 20, 57, 0.24);
}

.holiday:hover {
    background-color: rgba(217, 83, 79, 0.1);
}

.holiday-hover-area {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}
.holiday-tooltip::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

.react-calendar__tile:disabled {
    background-color: transparent !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #1a202c;
}
.react-calendar__tile--now {
    background: #e2f4ff !important;
}
.disabled-tooltip-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.react-calendar__navigation {
    display: flex;
    padding: 10px;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none !important;
}
.react-calendar__navigation__label__labelText--from {
    color: #1f2937;
    font-size: 13.962px;
    font-weight: 700;
}
.react-calendar__navigation__label {
    pointer-events: none;
    cursor: not-allowed;
}
.react-calendar__viewContainer {
    padding: 10px;
}
.rdt_TableCol {
    color: #fff;

    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 14px;
    letter-spacing: .15px;
}
