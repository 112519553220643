tr td:last-child {
    color: #2f80ed;
}

tr[style*='opacity: 0.5'] td:last-child {
    color: gray;
    cursor: not-allowed;
}

tr:hover td:last-child {
    color: white;
    background-color: #0077c0;
}

tr:not([style*='opacity: 0.5']):hover td:last-child {
    color: white;
    background-color: #0077c0;
}

.custom-tooltip {
    background-color: white !important;
    font-size: 11px !important;
    padding: 12px !important;
    border-radius: 8px !important;
    display: flex !important;
    position: relative;
    color: #4D5461 !important;
    flex-wrap: wrap;
    width: 150px;
}

/* data table customization */
.ofEne {
    white-space: wrap !important;
}
.rdt_TableHeadRow {
    background-color: #0077c0 !important;
    border-bottom-color: #e0e0e0 !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
}

.asc svg path {
    fill: white !important;
}
.desc svg path {
    fill: white !important;
}
.rdt_TableCol {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.rdt_TableCol .tooltip-text {
    visibility: hidden;
    width: 180px;
    background-color: white;
    color: #4d5461;
    color: #4d5461;
    border-radius: 5px;
    padding: 12px;
    position: absolute;
    z-index: 1;
    bottom: -95%;
    left: 32%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0px 4px 24px 0px rgba(8, 20, 57, 0.24);
    text-align: center;
}

.rdt_TableCol .tooltip-text::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    transform: translateX(-50%) rotate(180deg);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid white;
    border-top: 10px solid white;
}

.rdt_TableCol:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.tooltip-info {
    display: inline;
}

.partner-count {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: #2f80ed;
    color: white;
    cursor: pointer;
    text-align: center;
    text-align: center;
}
.partner-text {
    font-size: 10px;
}

/* Add this CSS class */
.govtHoliday {
    background-color: pink !important;
    color: black !important;
}

.selectedTag {
    border-radius: 4px;
    border: 1px solid #d3d5da;
    background: #f9fafb;
    color: #6c727f;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 8px;
}
.tagWidth {
    width: 320px;
}
.leaveEmployeeImg {
    height: 74px;
    width: 74px;
    border-radius: 74px;
    border: 2.5px solid #e5e6eb;
}
.leaveEmployeeName {
    color: #212936;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.22px;
}
.leaveEmployeeDesignation {
    color: #4d5461;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
}
.leaveSupervisor {
    color: #000;
    font-size: 12px;
    font-weight: 500;
}
.employeeTotalLeave {
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
}
.employeeTotalLeaveGreen {
    background: rgba(18, 183, 106, 0.08);
    color: #12b76a;
}
.employeeTotalLeaveYellow {
    background: rgba(255, 140, 33, 0.08);
    color: #ff8c21;
}
.employeeTotalLeaveRed {
    background: rgba(227, 0, 0, 0.08);
    color: #e30000;
}

.employeeTotalApprovedLeaveDay {
    font-weight: 700;
}

.leaveText {
    color: #0b0a0f;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
    padding-bottom: 8px;
}
