.custom-tooltip {
    border-radius: 4px;
    border: 1px solid #e5e6eb;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(100, 100, 102, 0.1);
    padding: 12px;
}

.recharts-layer:focus {
    outline: none !important;
}

/* Calender css */
.text {
    color: #6c727f;
}
.active {
    background-color: #0077c0;
    color: white;
}

/* react data table */

.css-a5mhaz {
    padding: 0px !important;
}

/* Custom Loader */
.loader {
    border: 4px solid #f3f3f3; /* Light gray */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rdt_TableRow {
    cursor: pointer;
}
.rdt_TableRow:hover {
    background-color: #0077c0;
    color: #fff;
}
.rdt_TableRow:nth-child(even) {
    background-color: #f2f2f2;
}
.rdt_TableRow:nth-child(even):hover {
    background-color: #0077c0;
    color: #fff;
}

.lightTooltip {
    display: block;
    max-width: 180px;
    white-space: normal;
    word-wrap: break-word;
    padding: 10px;
    white-space: normal;
    word-wrap: break-word;
    padding: 10px;
    background-color: #fff;
    color: #000;
    border: 1px solid #ddd;
    border-radius: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
    z-index: 9999 !important;
    text-align: center;
}
#my-tooltip {
    opacity: 1 !important;
    transition: opacity 0s !important;
}
.unplannedLeave {
    font-size: 12px;
    color: #9ea3ae;
    font-weight: 400;
}
.WubHI:hover,
.WubHI:focus {
    opacity: 9 !important;
}
.employeeImg {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}
